export const generateBoofUsername = (userId) => {
    const adjectives = ['Crazy', 'Silly', 'Funky', 'Groovy', 'Jazzy', 'Wacky', 'Zany', 'Quirky', 'Bouncy', 'Fluffy'];
    const boofNouns = ['Boof', 'Boofster', 'BoofMaster', 'BoofNinja', 'BoofWizard', 'BoofLord', 'BoofKing', 'BoofQueen', 'BoofChamp', 'BoofGuru'];
    
    if (!userId || userId.length < 24) {
        console.log('Invalid userId:', userId);
        return 'UnknownBoof000';
    }

    const adjectiveIndex = Math.abs(parseInt(userId.substr(0, 8), 16)) % adjectives.length;
    const boofNounIndex = Math.abs(parseInt(userId.substr(8, 8), 16)) % boofNouns.length;
    const numberPart = Math.abs(parseInt(userId.substr(16, 4), 16)) % 1000;

    return `${adjectives[adjectiveIndex]}${boofNouns[boofNounIndex]}${numberPart.toString().padStart(3, '0')}`;
};
