import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/HomePage.css';
import '../styles/styles.css';
import logo from '../assets/BOOF.svg'
import MatrixBackground from './MatrixBackground';
import Chatbox from './Chatbox';
import { analytics } from './firebase-config';
import { logEvent } from 'firebase/analytics';
import CloudShader from './CloudShader';
import Navbar from './Navbar';
import { generateBoofUsername } from '../utils';

const HomePage = ({ userId }) => {
  const [currentTime, setCurrentTime] = useState('');
  const [countdown, setCountdown] = useState('');
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [chatMessages, setChatMessages] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [boofUsername, setBoofUsername] = useState('');
  const ws = useRef(null);

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_title: 'Home Page',
        page_location: window.location.href,
        page_path: window.location.pathname
      });
    }
  }, []);

  const addMessage = useCallback((message) => {
    setChatMessages(prevMessages => {
      if (!prevMessages.some(msg => msg.clientId === message.clientId && msg.timestamp === message.timestamp)) {
        if (analytics) {
          logEvent(analytics, 'chat_message_sent', {
            user_id: message.userId
          });
        }
        return [...prevMessages, {
          ...message,
          isSelf: message.userId === userId
        }];
      }
      return prevMessages;
    });
  }, [userId]);

  const updateOnlineUsers = useCallback((count) => {
    setOnlineUsers(count);
    if (analytics) {
      logEvent(analytics, 'online_users_updated', {
        count: count
      });
    }
  }, []);

  const handleScreenClick = useCallback(() => {
    setClickCount(prevCount => {
      const newCount = prevCount + 1;
      console.log('Screen clicked. New count:', newCount);
      if (analytics) {
        logEvent(analytics, 'screen_clicked', {
          count: newCount
        });
      }
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(JSON.stringify({ type: 'updateClickCount', count: newCount }));
      }
      return newCount;
    });
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleScreenClick);
    return () => {
      document.removeEventListener('click', handleScreenClick);
    };
  }, [handleScreenClick]);

  const connectWebSocket = useCallback(() => {
    ws.current = new WebSocket('wss://boof-websocket-server-4e578b56fa63.herokuapp.com');

    ws.current.onopen = () => {
      console.log('WebSocket connected');
      ws.current.send(JSON.stringify({ type: 'identify', userId: userId }));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log('Received message in HomePage:', message);
      if (message.type === 'userCount') {
        updateOnlineUsers(message.count);
      } else if (message.type === 'chat') {
        addMessage(message);
      } else if (message.type === 'chatHistory') {
        setChatMessages(message.messages.map(msg => ({
          ...msg,
          isSelf: msg.userId === userId
        })));
      } else if (message.type === 'clickCount') {
        setClickCount(message.count);
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
      setTimeout(connectWebSocket, 1000);
    };
  }, [userId, addMessage, updateOnlineUsers]);

  useEffect(() => {
    const targetDate = new Date('October 31, 2024 00:00:00').getTime();
    
    const formatTime = (date) => {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
      return `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}.${year} ${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm} ${timezone}`;
    };

    const calculateCountdown = (distance) => {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursCountdown = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesCountdown = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const secondsCountdown = Math.floor((distance % (1000 * 60)) / 1000);
      const millisecondsCountdown = Math.floor((distance % 1000) / 10);
      return `${days.toString().padStart(2, '0')} : ${hoursCountdown.toString().padStart(2, '0')} : ${minutesCountdown.toString().padStart(2, '0')} : ${secondsCountdown.toString().padStart(2, '0')} : ${millisecondsCountdown.toString().padStart(2, '0')}`;
    };

    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(formatTime(now));

      const distance = targetDate - now.getTime();
      if (distance < 0) {
        clearInterval(timer);
        setCountdown("EXPIRED");
        if (analytics) {
          logEvent(analytics, 'countdown_expired');
        }
      } else {
        setCountdown(calculateCountdown(distance));
      }
    }, 10);

    if (userId) {
      connectWebSocket();
      const generatedUsername = generateBoofUsername(userId);
      setBoofUsername(generatedUsername);
    }

    return () => {
      clearInterval(timer);
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [userId, connectWebSocket]);

  return (
    <>
      <Navbar userId={userId} />
      <MatrixBackground />
      <CloudShader />
      <div className="coming-soon-container" style={{ position: 'relative', zIndex: 2 }}>
        <div className="header">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <p className="current-time">{currentTime}</p>
        <p className="wen-boof">WEN BOOF.</p>
        <p className="countdown">{countdown}</p>
        <p className="click-counter"><strong>{clickCount}</strong></p>
        <div className="online-users vibrate">
          <span className="live-dot"></span>
          {onlineUsers} BOOFER{onlineUsers !== 1 && 's'} online!
        </div>
      </div>
      <Chatbox 
        ws={ws} 
        messages={chatMessages} 
        addMessage={addMessage} 
        userId={userId}
        onlineUsers={onlineUsers}
        boofUsername={boofUsername}
      />
    </>
  );
};

export default HomePage;
