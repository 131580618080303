import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import MultiplierGame from './components/MultiplierGame';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let userIdFromCookie = Cookies.get('userId');
    if (!userIdFromCookie) {
      userIdFromCookie = uuidv4();
      Cookies.set('userId', userIdFromCookie, { expires: 365 });
    }
    setUserId(userIdFromCookie);
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<HomePage userId={userId} />} />
          <Route path="/g/boof" element={<MultiplierGame userId={userId} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
