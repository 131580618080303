import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import '../styles/MultiplierGame.css';
import logo from '../assets/BOOF.svg';
import Navbar from './Navbar';
import Chatbox from './Chatbox';
import { generateBoofUsername } from '../utils';

const MultiplierGame = ({ userId }) => {
  const [gameState, setGameState] = useState({
    isPlaying: false,
    multiplier: 1,
    crashPoint: null,
    countdown: 5,
    gameHistory: []
  });
  const [betAmount, setBetAmount] = useState(10);
  const [balance, setBalance] = useState(1000);
  const [currentBet, setCurrentBet] = useState(0);
  const [hasBetPlaced, setHasBetPlaced] = useState(false);
  const [hasCashedOut, setHasCashedOut] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [gameResult, setGameResult] = useState('');
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [localCountdown, setLocalCountdown] = useState(5);
  const [chatMessages, setChatMessages] = useState([]);
  const [boofUsername, setBoofUsername] = useState('');
  const ws = useRef(null);
  const chartStartTimeRef = useRef(Date.now());

  const addMessage = useCallback((message) => {
    setChatMessages(prevMessages => {
      if (!prevMessages.some(msg => msg.clientId === message.clientId && msg.timestamp === message.timestamp)) {
        return [...prevMessages, {
          ...message,
          isSelf: message.userId === userId
        }];
      }
      return prevMessages;
    });
  }, [userId]);

  const connectWebSocket = useCallback(() => {
    ws.current = new WebSocket('wss://boof-websocket-server-4e578b56fa63.herokuapp.com');

    ws.current.onopen = () => {
      console.log('WebSocket connected');
      ws.current.send(JSON.stringify({ type: 'identify', userId: userId }));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'gameState') {
        setGameState(prevState => {
          if (prevState.isPlaying && !message.state.isPlaying) {
            setGameResult('BOOF!');
            setTimeout(() => setGameResult(''), 1000);
            setLocalCountdown(5);
          } else if (!prevState.isPlaying && message.state.isPlaying) {
            setLocalCountdown(5);
          }
          return message.state;
        });
        if (message.state.isPlaying) {
          const currentTime = Date.now();
          const elapsedTime = (currentTime - chartStartTimeRef.current) / 1000;
          setChartData(prevData => [
            ...prevData,
            { time: elapsedTime, value: message.state.multiplier }
          ]);
        } else if (message.state.countdown === 5) {
          setChartData([]);
          chartStartTimeRef.current = Date.now();
        }
      } else if (message.type === 'userCount') {
        setOnlineUsers(message.count);
      } else if (message.type === 'chat') {
        addMessage(message);
      } else if (message.type === 'chatHistory') {
        setChatMessages(message.messages.map(msg => ({
          ...msg,
          isSelf: msg.userId === userId
        })));
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
      setTimeout(connectWebSocket, 1000);
    };
  }, [userId, addMessage]);

  useEffect(() => {
    if (userId) {
      connectWebSocket();
      const generatedUsername = generateBoofUsername(userId);
      setBoofUsername(generatedUsername);
    }
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [userId, connectWebSocket]);

  useEffect(() => {
    let interval;
    if (!gameState.isPlaying && localCountdown > 0) {
      interval = setInterval(() => {
        setLocalCountdown(prev => {
          if (prev <= 0.01) {
            clearInterval(interval);
            return 0;
          }
          return prev - 0.01;
        });
      }, 10);
    }
    return () => clearInterval(interval);
  }, [gameState.isPlaying, localCountdown]);

  const placeBet = useCallback(() => {
    if (betAmount > 0 && betAmount <= balance && !hasBetPlaced && !gameState.isPlaying) {
      setBalance(prev => prev - betAmount);
      setCurrentBet(betAmount);
      setHasBetPlaced(true);
      ws.current.send(JSON.stringify({ type: 'placeBet', amount: betAmount }));
    }
  }, [betAmount, balance, hasBetPlaced, gameState.isPlaying]);

  const cashOut = useCallback(() => {
    if (gameState.isPlaying && currentBet > 0 && !hasCashedOut) {
      const winAmount = currentBet * gameState.multiplier;
      setBalance(prev => prev + winAmount);
      setHasCashedOut(true);
      setCurrentBet(0);
      setGameResult(`YOU WIN $${winAmount.toFixed(2)}!!!`);
      ws.current.send(JSON.stringify({ type: 'cashOut' }));
    }
  }, [gameState.isPlaying, gameState.multiplier, currentBet, hasCashedOut]);

  useEffect(() => {
    if (!gameState.isPlaying) {
      setHasBetPlaced(false);
      setHasCashedOut(false);
      setCurrentBet(0);
    }
  }, [gameState.isPlaying]);

  const currentWinAmount = hasBetPlaced && !hasCashedOut ? currentBet * gameState.multiplier : 0;

  return (
    <div className="boof-multiplier-game">
      <Navbar userId={userId} />
      <div className="scrollable-container">
        <div className="game-content">
          <div className="game-header">
            <img src={logo} alt="BOOF Logo" className="boof-logo" />
          </div>
          <div className="game-card">
            <h2>Game Status</h2>
            <p className={`multiplier ${gameResult.includes('WIN') ? 'win' : gameResult === 'BOOF!' ? 'lose' : ''}`}>
              {gameState.isPlaying ? `${gameState.multiplier.toFixed(2)}x` : 
               gameResult ? gameResult :
               localCountdown > 0 ? `Next game in ${localCountdown.toFixed(2)}` : 
               'Start Game'}
            </p>
            {hasBetPlaced && gameState.isPlaying && !hasCashedOut && (
              <p className="current-win">Current win: ${currentWinAmount.toFixed(2)}</p>
            )}
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                <XAxis 
                  dataKey="time" 
                  stroke="#fff"
                  tickFormatter={(value) => value.toFixed(1)}
                />
                <YAxis 
                  stroke="#fff" 
                  domain={[1, 'auto']}
                  tickFormatter={(value) => value.toFixed(2)}
                />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#ff0000" 
                  dot={false}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="bet-controls">
            <p className="bet-amount">
              Bet Amount: ${betAmount.toFixed(2)}
            </p>
            <input
              type="range"
              min={1}
              max={balance}
              step={0.1}
              value={betAmount}
              onChange={(e) => setBetAmount(parseFloat(e.target.value))}
              className="bet-slider"
              disabled={gameState.isPlaying || hasBetPlaced}
            />
            <div className="bet-buttons">
              <button onClick={() => setBetAmount(1)} className="bet-button" disabled={gameState.isPlaying || hasBetPlaced}>Min</button>
              <button onClick={() => setBetAmount(Math.floor(betAmount / 2))} className="bet-button" disabled={gameState.isPlaying || hasBetPlaced}>1/2</button>
              <button onClick={() => setBetAmount(Math.min(betAmount * 2, balance))} className="bet-button" disabled={gameState.isPlaying || hasBetPlaced}>2x</button>
              <button onClick={() => setBetAmount(balance)} className="bet-button" disabled={gameState.isPlaying || hasBetPlaced}>Max</button>
            </div>
            <div className="game-buttons">
              {gameState.isPlaying ? (
                <button
                  onClick={cashOut}
                  disabled={currentBet === 0 || hasCashedOut}
                  className="cashout-button"
                >
                  Cash Out
                </button>
              ) : (
                <button
                  onClick={placeBet}
                  disabled={hasBetPlaced || betAmount > balance}
                  className="place-bet-button"
                >
                  Place Bet
                </button>
              )}
            </div>
          </div>
          <p className="balance">Balance: ${balance.toFixed(2)}</p>
          <div className="history-card">
            <h2>Game History</h2>
            <div className="history-list">
              {gameState.gameHistory.map((game, index) => (
                <span
                  key={index}
                  className={`history-item ${game.won ? 'won' : 'lost'}`}
                >
                  {game.multiplier.toFixed(2)}x
                </span>
              ))}
            </div>
          </div>
          <div className="online-users vibrate">
            <span className="live-dot"></span>
            {onlineUsers} BOOFER{onlineUsers !== 1 && 's'} online!
          </div>
          <div className="how-to-play">
            <h2>How to Play</h2>
            <p>
              1. Set your bet amount using the slider or quick bet buttons.
              2. Click "Place Bet" before the countdown ends to join the game.
              3. Watch the multiplier increase. The higher it goes, the more you can win!
              4. Click "Cash Out" any time before the game crashes to secure your winnings.
              5. If you don't cash out before the crash, you lose your bet.
              6. Be strategic - the game can crash at any moment, even below 1.00x!
            </p>
          </div>
        </div>
      </div>
      <Chatbox 
        ws={ws} 
        messages={chatMessages} 
        addMessage={addMessage} 
        userId={userId}
        onlineUsers={onlineUsers}
        boofUsername={boofUsername}
      />
    </div>
  );
};

export default MultiplierGame;
