import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const CloudShader = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    mountRef.current.appendChild(renderer.domElement);

    const geometry = new THREE.PlaneGeometry(2, 2);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0 },
        u_resolution: { value: new THREE.Vector2() },
        u_mouse: { value: new THREE.Vector2() },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec2 u_resolution;
        uniform vec2 u_mouse;
        uniform float u_time;
        varying vec2 vUv;

        const int OCTAVES = 8;

        float random (in vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }

        float noise (in vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);

          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));

          vec2 u = f * f * (3.0 - 2.0 * f);

          return mix(a, b, u.x) +
                  (c - a)* u.y * (1.0 - u.x) +
                  (d - b) * u.x * u.y;
        }

        float fbm (in vec2 st) {
          float value = 0.0;
          float amplitude = .5;
          float frequency = 0.;

          for (int i = 0; i < OCTAVES; i++) {
            value += amplitude * noise(st);
            st *= 2.;
            amplitude *= .5;
          }
          return value;
        }

        void main() {
          vec2 st = gl_FragCoord.xy/u_resolution.xy;
          st.x *= u_resolution.x/u_resolution.y;

          vec2 mouse = u_mouse/u_resolution;
          float dist = distance(st, mouse);
          vec2 distortion = (st - mouse) * (1.0 - smoothstep(0.0, 0.5, dist)) * 0.3;

          vec3 color = vec3(0.0);

          vec2 q = vec2(0.);
          q.x = fbm( st + distortion + 0.02*u_time);
          q.y = fbm( st + distortion + vec2(1.0));

          vec2 r = vec2(0.);
          r.x = fbm( st + distortion + 1.0*q + vec2(1.7,9.2)+ 0.25*u_time );
          r.y = fbm( st + distortion + 1.0*q + vec2(8.3,2.8)+ 0.226*u_time);

          float f = fbm(st + distortion + r);

          color = mix(vec3(0.101961,0.619608,0.666667),
                      vec3(0.666667,0.666667,0.498039),
                      clamp((f*f)*4.0,0.0,1.0));

          color = mix(color,
                      vec3(0,0,0.164706),
                      clamp(length(q),0.0,1.0));

          color = mix(color,
                      vec3(0.666667,1,1),
                      clamp(length(r.x),0.0,1.0));

          float alpha = smoothstep(0.3, 0.7, f);
          gl_FragColor = vec4(color, alpha * 0.5);
        }
      `,
      transparent: true,
    });

    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    camera.position.z = 1;

    const animate = () => {
      requestAnimationFrame(animate);
      material.uniforms.u_time.value += 0.01;
      renderer.render(scene, camera);
    };

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
      material.uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
    };

    const handleMouseMove = (event) => {
      material.uniforms.u_mouse.value.set(event.clientX, window.innerHeight - event.clientY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);

    handleResize();
    animate();

    const mountRefCurrent = mountRef.current;

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      if (mountRefCurrent) {
        mountRefCurrent.removeChild(renderer.domElement);
      }
      geometry.dispose();
      material.dispose();
      renderer.dispose();
    };
  }, []);

  return <div ref={mountRef} style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1 }} />;
};

export default CloudShader;