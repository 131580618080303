import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/Chatbox.css';

const Chatbox = ({ ws, messages, addMessage, userId, onlineUsers, boofUsername }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const messagesEndRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const colorMap = useRef(new Map());

  const scrollToBottom = useCallback(() => {
    if (isOpen && chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [isOpen]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isOpen, scrollToBottom]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim() && ws.current && ws.current.readyState === WebSocket.OPEN) {
      const message = {
        type: 'chat',
        text: inputMessage,
        timestamp: new Date().toISOString(),
        userId: userId,
        boofUsername: boofUsername,
      };
      ws.current.send(JSON.stringify(message));
      setInputMessage('');
    }
  };

  const getColorForUser = (userId) => {
    if (!userId) return '#FFFFFF';
    
    if (!colorMap.current.has(userId)) {
      let hash = 0;
      for (let i = 0; i < userId.length; i++) {
        hash = userId.charCodeAt(i) + ((hash << 5) - hash);
      }
      
      const h = hash % 360;
      const s = 70 + (hash % 30);
      const l = 60 + (hash % 20);
      
      colorMap.current.set(userId, `hsl(${h}, ${s}%, ${l}%)`);
    }
    return colorMap.current.get(userId);
  };

  return (
    <div className={`chatbox ${isOpen ? 'open' : ''}`}>
      <button className="chat-toggle" onClick={toggleChat}>
        {isOpen ? 'Close BOOFCHAT' : `BOOFCHAT (${onlineUsers} online)`}
      </button>
      {isOpen && (
        <div className="chat-container">
          <div className="chat-header">
            BOOFCHAT - {onlineUsers} BOOFER{onlineUsers !== 1 && 's'} online
          </div>
          <div className="chat-messages" ref={chatMessagesRef}>
            {messages.map((msg, index) => (
              <div key={`${msg.clientId}-${msg.timestamp}`} className={`message ${msg.isSelf ? 'self' : ''}`}>
                <span 
                  className="timestamp" 
                  style={{ color: getColorForUser(msg.userId) }}
                >
                  {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
                <span className="username" style={{ color: '#ffffff' }}>{msg.boofUsername || 'Unknown BOOFER'}: </span>
                <span className="text">{msg.text}</span>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={sendMessage} className="chat-input">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbox;
