import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import { generateBoofUsername } from '../utils';
import { ReactComponent as BoofLogo } from '../assets/boof-logo.svg';  // Import your BOOF logo SVG
import { ReactComponent as ProfileIcon } from '../assets/profile-icon.svg';  // Import your profile icon SVG

const Navbar = ({ userId }) => {
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (userId) {
            console.log('Generating username for userId:', userId);
            const generatedUsername = generateBoofUsername(userId);
            setUsername(generatedUsername);
        }
    }, [userId]);

    const toggleUserInfo = () => {
        setShowUserInfo(!showUserInfo);
    };

    return (
        <nav className="navbar">
            <div className="navbar-content">
                <div className="navbar-logo">
                    <Link to="/">
                        <BoofLogo className="boof-logo-svg" />
                    </Link>
                </div>
                <div className="navbar-links">
                    {/* <Link to="/g/boof">BOOF Game</Link> */}
                </div>
                <div className="navbar-avatar" onClick={toggleUserInfo}>
                    <ProfileIcon className="profile-icon-svg" />
                </div>
            </div>
            {showUserInfo && (
                <div className="user-info-popup">
                    <p>BoofID: {username || 'Loading...'}</p>
                    <p className="uuid-info">UUID: {userId || 'Not available'}</p>
                    <button onClick={toggleUserInfo}>Close</button>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
