import React, { useEffect, useRef } from 'react';

const MatrixBackground = () => {
  const canvasRef = useRef(null);
  const dropsRef = useRef([]);
  const columnsRef = useRef(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789BOOF.'
      + '日本語漢字あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん';
    const fontSize = 14;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      
      const newColumns = Math.ceil(canvas.width / fontSize);
      
      if (newColumns > columnsRef.current) {
        // Add new drops for additional columns
        for (let i = columnsRef.current; i < newColumns; i++) {
          dropsRef.current[i] = 1;
        }
      }
      
      columnsRef.current = newColumns;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    // Initialize drops only once
    if (dropsRef.current.length === 0) {
      for (let i = 0; i < columnsRef.current; i++) {
        dropsRef.current[i] = 1;
      }
    }

    function draw() {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#FF0000'; // Red color
      ctx.font = `${fontSize}px "MS Gothic", monospace`;

      for (let i = 0; i < columnsRef.current; i++) {
        const text = Math.random() < 0.1 ? 'BOOF.'[Math.floor(Math.random() * 5)] : 
                     characters.charAt(Math.floor(Math.random() * characters.length));
        ctx.fillText(text, i * fontSize, dropsRef.current[i] * fontSize);

        if (dropsRef.current[i] * fontSize > canvas.height && Math.random() > 0.975) {
          dropsRef.current[i] = 0;
        }
        dropsRef.current[i]++;
      }
    }

    const interval = setInterval(draw, 33);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />;
};

export default MatrixBackground;